import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _mjmlSocial3 from "mjml-social";

var _mjmlSocial2 = "default" in _mjmlSocial3 ? _mjmlSocial3.default : _mjmlSocial3;

import * as _mjmlNavbar3 from "mjml-navbar";

var _mjmlNavbar2 = "default" in _mjmlNavbar3 ? _mjmlNavbar3.default : _mjmlNavbar3;

import * as _mjmlCarousel3 from "mjml-carousel";

var _mjmlCarousel2 = "default" in _mjmlCarousel3 ? _mjmlCarousel3.default : _mjmlCarousel3;

import * as _mjmlAccordion3 from "mjml-accordion";

var _mjmlAccordion2 = "default" in _mjmlAccordion3 ? _mjmlAccordion3.default : _mjmlAccordion3;

import * as _mjmlBody3 from "mjml-body";

var _mjmlBody2 = "default" in _mjmlBody3 ? _mjmlBody3.default : _mjmlBody3;

import * as _mjmlHead3 from "mjml-head";

var _mjmlHead2 = "default" in _mjmlHead3 ? _mjmlHead3.default : _mjmlHead3;

import * as _mjmlHeadAttributes3 from "mjml-head-attributes";

var _mjmlHeadAttributes2 = "default" in _mjmlHeadAttributes3 ? _mjmlHeadAttributes3.default : _mjmlHeadAttributes3;

import * as _mjmlHeadBreakpoint3 from "mjml-head-breakpoint";

var _mjmlHeadBreakpoint2 = "default" in _mjmlHeadBreakpoint3 ? _mjmlHeadBreakpoint3.default : _mjmlHeadBreakpoint3;

import * as _mjmlHeadHtmlAttributes3 from "mjml-head-html-attributes";

var _mjmlHeadHtmlAttributes2 = "default" in _mjmlHeadHtmlAttributes3 ? _mjmlHeadHtmlAttributes3.default : _mjmlHeadHtmlAttributes3;

import * as _mjmlHeadFont3 from "mjml-head-font";

var _mjmlHeadFont2 = "default" in _mjmlHeadFont3 ? _mjmlHeadFont3.default : _mjmlHeadFont3;

import * as _mjmlHeadPreview3 from "mjml-head-preview";

var _mjmlHeadPreview2 = "default" in _mjmlHeadPreview3 ? _mjmlHeadPreview3.default : _mjmlHeadPreview3;

import * as _mjmlHeadStyle3 from "mjml-head-style";

var _mjmlHeadStyle2 = "default" in _mjmlHeadStyle3 ? _mjmlHeadStyle3.default : _mjmlHeadStyle3;

import * as _mjmlHeadTitle3 from "mjml-head-title";

var _mjmlHeadTitle2 = "default" in _mjmlHeadTitle3 ? _mjmlHeadTitle3.default : _mjmlHeadTitle3;

import * as _mjmlHero3 from "mjml-hero";

var _mjmlHero2 = "default" in _mjmlHero3 ? _mjmlHero3.default : _mjmlHero3;

import * as _mjmlButton3 from "mjml-button";

var _mjmlButton2 = "default" in _mjmlButton3 ? _mjmlButton3.default : _mjmlButton3;

import * as _mjmlColumn3 from "mjml-column";

var _mjmlColumn2 = "default" in _mjmlColumn3 ? _mjmlColumn3.default : _mjmlColumn3;

import * as _mjmlDivider3 from "mjml-divider";

var _mjmlDivider2 = "default" in _mjmlDivider3 ? _mjmlDivider3.default : _mjmlDivider3;

import * as _mjmlGroup3 from "mjml-group";

var _mjmlGroup2 = "default" in _mjmlGroup3 ? _mjmlGroup3.default : _mjmlGroup3;

import * as _mjmlImage3 from "mjml-image";

var _mjmlImage2 = "default" in _mjmlImage3 ? _mjmlImage3.default : _mjmlImage3;

import * as _mjmlRaw3 from "mjml-raw";

var _mjmlRaw2 = "default" in _mjmlRaw3 ? _mjmlRaw3.default : _mjmlRaw3;

import * as _mjmlSection3 from "mjml-section";

var _mjmlSection2 = "default" in _mjmlSection3 ? _mjmlSection3.default : _mjmlSection3;

import * as _mjmlSpacer3 from "mjml-spacer";

var _mjmlSpacer2 = "default" in _mjmlSpacer3 ? _mjmlSpacer3.default : _mjmlSpacer3;

import * as _mjmlText3 from "mjml-text";

var _mjmlText2 = "default" in _mjmlText3 ? _mjmlText3.default : _mjmlText3;

import * as _mjmlTable3 from "mjml-table";

var _mjmlTable2 = "default" in _mjmlTable3 ? _mjmlTable3.default : _mjmlTable3;

import * as _mjmlWrapper3 from "mjml-wrapper";

var _mjmlWrapper2 = "default" in _mjmlWrapper3 ? _mjmlWrapper3.default : _mjmlWrapper3;

import _dependencies2 from "./dependencies";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2.default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mjmlSocial = _mjmlSocial2;
var _mjmlNavbar = _mjmlNavbar2;
var _mjmlCarousel = _mjmlCarousel2;
var _mjmlAccordion = _mjmlAccordion2;

var _mjmlBody = _interopRequireDefault(_mjmlBody2);

var _mjmlHead = _interopRequireDefault(_mjmlHead2);

var _mjmlHeadAttributes = _interopRequireDefault(_mjmlHeadAttributes2);

var _mjmlHeadBreakpoint = _interopRequireDefault(_mjmlHeadBreakpoint2);

var _mjmlHeadHtmlAttributes = _interopRequireDefault(_mjmlHeadHtmlAttributes2);

var _mjmlHeadFont = _interopRequireDefault(_mjmlHeadFont2);

var _mjmlHeadPreview = _interopRequireDefault(_mjmlHeadPreview2);

var _mjmlHeadStyle = _interopRequireDefault(_mjmlHeadStyle2);

var _mjmlHeadTitle = _interopRequireDefault(_mjmlHeadTitle2);

var _mjmlHero = _interopRequireDefault(_mjmlHero2);

var _mjmlButton = _interopRequireDefault(_mjmlButton2);

var _mjmlColumn = _interopRequireDefault(_mjmlColumn2);

var _mjmlDivider = _interopRequireDefault(_mjmlDivider2);

var _mjmlGroup = _interopRequireDefault(_mjmlGroup2);

var _mjmlImage = _interopRequireDefault(_mjmlImage2);

var _mjmlRaw = _interopRequireDefault(_mjmlRaw2);

var _mjmlSection = _interopRequireDefault(_mjmlSection2);

var _mjmlSpacer = _interopRequireDefault(_mjmlSpacer2);

var _mjmlText = _interopRequireDefault(_mjmlText2);

var _mjmlTable = _interopRequireDefault(_mjmlTable2);

var _mjmlWrapper = _interopRequireDefault(_mjmlWrapper2);

var _dependencies = _interopRequireDefault(_dependencies2);

const components = [_mjmlBody.default, _mjmlHead.default, _mjmlHeadAttributes.default, _mjmlHeadBreakpoint.default, _mjmlHeadHtmlAttributes.default, _mjmlHeadFont.default, _mjmlHeadPreview.default, _mjmlHeadStyle.default, _mjmlHeadTitle.default, _mjmlHero.default, _mjmlButton.default, _mjmlColumn.default, _mjmlDivider.default, _mjmlGroup.default, _mjmlImage.default, _mjmlRaw.default, _mjmlSection.default, _mjmlSpacer.default, _mjmlText.default, _mjmlTable.default, _mjmlWrapper.default, _mjmlSocial.Social, _mjmlSocial.SocialElement, _mjmlNavbar.Navbar, _mjmlNavbar.NavbarLink, _mjmlAccordion.Accordion, _mjmlAccordion.AccordionElement, _mjmlAccordion.AccordionText, _mjmlAccordion.AccordionTitle, _mjmlCarousel.Carousel, _mjmlCarousel.CarouselImage];
const presetCore = {
  components,
  dependencies: _dependencies.default
};
var _default = presetCore;
exports.default = _default;
exports = exports.default;
export default exports;
export const __esModule = exports.__esModule;